// 登录页路由
export const login = {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "approval" */"@/components/Login.vue")
}
// 底部导航路由
export const bottomNav = [
    {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/Index.vue"),
        meta: {
            activeNav: 'index',
            title: '接单广场',
            icon: 'index-page',
			dot:0
        }
    },
	{
	    path: '/bidding',
	    name: 'bidding',
	    component: () => import(/* webpackChunkName: "approval" */ "@/components/Bidding.vue"),
	    meta: {
	        activeNav: 'bidding',
	        title: '正在竞价',
	        icon: 'bidding-page',
			dot:0
	    }
	},
    {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "approval" */"@/components/Order.vue"),
        meta: {
            activeNav: 'order',
            title: '订单处理',
            icon: 'order-page',
			dot:0
        }
    },
    {
        path: '/capital-info',
        name: 'capital-info',
        component: () => import(/* webpackChunkName: "approval" */"@/components/CapitalInfo.vue"),
        meta: {
            activeNav: 'capital-info',
            title: '商家中心',
            icon: 'info-page',
			dot:0,
            header: {
                title: '商家中心',
                style: {
                    background: '#3C7BE3',
                    color: '#fff',
                    height: '3.05rem',
                    boxShadow: 'none',
                    border: 'none'
                }
            }
        }
    }
]

// 授权页面
export const ErrorPage = [
    {
        path: '/402',
        name: 402,
        component: () => import(/* webpackChunkName: "approval" */ "@/components/Authed/402.vue"),
        meta: {
            header: {
                title: '审核',
                style: {
                    display: 'none'
                }
            }
        }
    },
    {
        path: '/403',
        name: 403,
        component: () => import(/* webpackChunkName: "approval" */"@/components/Authed/403.vue"),
        meta: {
            header: {
                title: '审核',
                style: {
                    display: 'none'
                }
            }
        }
    },
    {
        path: '/404',
        name: 404,
        component: () => import(/* webpackChunkName: "approval" */"@/components/Authed/404.vue"),
        meta: {
            header: {
                title: '审核',
                style: {
                    display: 'none'
                }
            }
        }
    },
    {
        path: '/405',
        name: 405,
        component: () => import(/* webpackChunkName: "approval" */"@/components/Authed/405.vue"),
        meta: {
            header: {
                title: '审核',
                style: {
                    display: 'none'
                }
            }
        }
    }
]

const routes = [
    ...bottomNav,
    ...ErrorPage,
    {
        path: '/',
        redirect: '/index'
    },
    // 绑定、审核、审核结果
    {
        path: '/examine',
        name: 'examine',
        component: () => import(/* webpackChunkName: "approval" */"@/components/Examine.vue")
    },
    // 接单
    {
        path: '/detail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/Detail.vue"),
        meta: {
            header: {
                title: '出票详情',
                goBack: true
            }
        }
    },
    {
        path: '/search-habits-manage',
        name: 'search-habits-manage',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/SearchHabitsManage.vue"),
        meta: {
            header: {
                title: '关键词管理',
                goBack: true
            }
        }
    },
    // 出票展示列表
    {
        path: '/ticket',
        name: 'ticket',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/Ticket.vue"),
        meta: {
            header: {
                title: '取票码',
                goBack: true
            }
        }
    },
    //  用户信息编辑
    {
        path: '/userinfo-edit',
        name: 'userinfo-edit',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/UserinfoEdit.vue"),
        meta: {
            activeNav: 'capital-info'
        }
    },
    // 提现
    {
        path: '/cash-out',
        name: 'cash-out',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/CashOut.vue"),
        meta: {
            header: {
                title: '提现',
                goBack: true
            }
        }
    },
    // 累计结算订单
    {
        path: '/settle-order',
        name: 'settle-order',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/SettleOrder.vue"),
        meta: {
            header: {
                title: '累积结算',
                goBack: true
            }
        }
    },
    // 冻结货款订单
    {
        path: '/frozen-order',
        name: 'frozen-order',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/FrozenOrder.vue"),
        meta: {
            header: {
                title: '冻结货款',
                goBack: true
            }
        }
    },
    // 提现记录
    {
        path: '/cash-out-log',
        name: 'cash-out-log',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/CashOutLog.vue"),
        meta: {
            header: {
                title: '提现记录',
                goBack: true
            }
        }
    },
    // 账户信息编辑
    {
        path: '/account-edit',
        name: 'account-edit',
        component: () => import(/* webpackChunkName: "approval" */ "@/components/AccountEdit.vue")
    }
]
routes.push(login)

export default routes