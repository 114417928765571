import { login } from './routes'

const host_url = window.location.origin
// const host_url = 'http://a001.uuorp.com'

export default {
    host_url,
    // 数据接口配置
    api_url: 'https://api.sumzc.com/',
    // api_url: 'http://news.sumzc.api/',
    // api_url: 'http://api.sumzc.l.com/',
    // 公众号 appid
    appid: 'wxddc4298b3653706b',
    repairLink(search, to) {
        window.location.href = this.host_url + '#' + to.path + search
    },
    jumpWechatAuth() {
        let redirect_url = encodeURIComponent(this.host_url + '#' + login.path);
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            this.appid + "&redirect_uri="+redirect_url+"&response_type=code&scope=snsapi_base#wechat_redirect"
    },
    getHost() {
        return this.host_url
    }
}