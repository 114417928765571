<template>
  <nav>
    <ul>
      <li v-for="(nav, key) in navs" :key="key" :style="'width:' + 100 / navs.length +'%;'">
        <div class="nav-box" :class="{'nav-tabs': true, 'nav-selected': active == nav.name}" @click="changeNav(nav.name)">
          <img :src="'image/'+ nav.meta.icon + (active == nav.name ? '-selected.png': '.png')" alt="">
          <span class="nav-title">{{nav.meta.title}}</span>
		  <div class="dot-box" v-if="nav.meta.dot > 0">
			  {{nav.meta.dot}}
		  </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import {bottomNav} from "@/routes";
import {mapActions} from "vuex";
export default {
  name: "BottomNav",
  props: {
    active: String
  },
  created() {
		this.getDot(res =>{
			for (var index = 0 ; index < res.length ; index++){
				var data = res[index]
				this.navs[data.index].meta.dot = data.count
			}
		});
  },
  data() {
    return {
      select: bottomNav[0].name,
      navs: bottomNav
    }
  },
  methods: {
	...mapActions(['getDot']),
    changeNav(value) {
		this.getDot(res =>{
			for (var index = 0 ; index < res.length ; index++){
				var data = res[index]
				this.navs[data.index].meta.dot = data.count
			}
		});
		this.$router.push({ name: value })
    }
  }
}
</script>

<style scoped>
.bottom-nav {
	border-top: 1px solid #D8D9D9;
	z-index: 100;
}

ul,
ol,
li,
dl {
    list-style-type: none;
}

.bottom-nav ul {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 1px 0 #000;
}

.bottom-nav li {
    background: #fff;
    height: 4.25rem;
    border: none;
    float: left;

    line-height: 1.3rem;
}

.nav-tabs {
    font-weight: bold;
    padding-top: 0.75rem;
    font-size: 0.8rem;
    display: block;
    text-align: center;
    line-height: 1.25rem;
    color: #8d93a1;
    border: none;
    background: #fff;
}

.nav-tabs>img {
    display: block;
    width: 1.13rem;
    height: 1.25rem;
    margin: 0 auto 0.5rem;
}

.nav-selected {
    color: #557EF8;
}

.nav-box {
    position: relative;
}

.dot-box {
    position: absolute;
    right: 1.3rem;
    top: 0.3125rem;
	background-color: #FE3521;
	width: 1.25rem;
	height: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
	font-size: 0.75rem;
}
</style>