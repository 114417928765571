import { createApp } from 'vue'
// 引入 vue 配套扩展 vuex & vue-router
import { createStore } from 'vuex'
import { createRouter, createWebHashHistory } from 'vue-router'
// 引入配置文件
import routes, { login } from './routes' // vue-router 路由配置
import storeData from './store' // vuex 的 store 配置
import config from './config' // 常规配置文件
// 引入 App 挂载主页
import App from './App.vue'
// 引入图标样式
import '@/assets/iconfont/iconfont.css'
// 引入 vant-ui
import Vant from 'vant';
import 'vant/lib/index.css';

// 创建 vuex 和 vue-router 的配置对象
const store = createStore(storeData)
const router = createRouter({
    history: createWebHashHistory(),
    routes
})




router.beforeEach((to) => {
    // 修复链接：将search参数变成hash参数
    if(window.location.search) {
        config.repairLink(window.location.search, to)
        return false
    }
    // 未获取到本地登录状态，并且非登录页面，跳转至微信授权页
    if (!store.getters.isAuthenticated && to.name != login.name) {
        config.jumpWechatAuth()
        return false
    }
    return true
})
const app = createApp(App)
// use vue-router、vuex
app.use(router)
app.use(store)
// use vant-ui
app.use(Vant)
app.mount('#app')