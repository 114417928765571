import axios from "axios";
import globalConfig from "./config";
import qs from 'qs'

export default {
    state() {
        return {
            authToken: null,
            userinfo: {},
            router: {},
            previewImg: null,
            ui: {}
        }
    },
    getters: {
        previewImg(state) {
          return state.previewImg
        },
        isAuthenticated(state, getters) {
            return getters.authToken == null ? false : true
        },
        userinfo(state) {
          return state.userinfo
        },
        authToken(state) {
            // 获取 vuex 中的 openid
            if (state.authToken) {
                return state.authToken
            }
            // 从本地存储获取 openid
            let token = localStorage.getItem('authToken')
            if (token) {
                state.authToken = token
                return state.authToken
            }
            return null
        }
    },
    mutations: {
        setAuthToken(state, token) {
            state.authToken = token
        },
        setRouter(state, router) {
            state.router = router
        },
        setUIFrameWork(state, ui) {
            state.ui = ui
        },
        setUserinfo(state, userinfo) {
            state.userinfo = userinfo
        },
        setPreviewImg(state, img) {
            state.previewImg = img
        }
    },
    actions: {
        closePreviewImg(context) {
            context.commit('setPreviewImg', null)
        },
        openPreviewImg(context, img) {
            context.commit('setPreviewImg', img)
        },
        getAuthTokenByCode(context, code) {
            let openid = localStorage.getItem('opneid')
            if (openid) {
                return openid
            }
            axios.get(globalConfig.api_url + 'orp/codeToAuthToken', {
                params: {
                    code
                }
            })
            .then(function (response) {
                if (response.data.code == 200) {
                    // vuex 存储 token
                    context.commit('setAuthToken', response.data.data.auth_token)
                    // 本地存储 token
                    localStorage.setItem('authToken', response.data.data.auth_token)
                    // 跳转页面
                    context.state.router.push({ name: 'index'})
                } else {
                    context.state.ui.dialog.alert({
                        message: response.data.msg,

                    })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        /**
         * 获取用户信息
         * @param context       store对象，内置属性和方法
         * @param callback      回调函数，返回userinfo信息
         * @param cache         是否使用store本地数据，false则通过api获取
         * @returns {boolean}
         */
        getUserinfo(context, { callback, cache = false }) {
            if (context.state.userinfo.Id && cache) {
                callback(context.state.userinfo)
                return true
            }
            context.dispatch('apiGet', {
                api: 'orp/getUserinfo',
                success: (res) => {
                    context.commit('setUserinfo', res.data)
                    callback(res.data)
                }
            })
            return true
        },
		getDot(context, callback) {
		    context.dispatch('apiGet', {
		        api: 'orp/getDot',
		        success: (res) => {
		            callback(res.data)
		        }
		    })
		},
		downgrade(context){
			context.dispatch('getUserinfo', {
				callback: userinfo => {
					if (userinfo.LevelId == 4 && userinfo.AlertMsg == 0) {
						context.state.ui.dialog.alert({
							title: '降级通知',
							message: "因超时出单你的级别已下降至【D级】\n1、你今日将无法参与订单竞价\n2、今晚00:00:00后级别会自动恢复降级\n申诉说明：如果您是因为客观原因造成的降级，请联系客服为你恢复级别。客服在人工核实后为你将级别调回原级别。",
							confirmButtonColor: 'red',
							confirmButtonText:'我知道了',
							className: 'dialog_message'
						})
						.then(() => {
							context.dispatch('apiPost', {
							    api: 'orp/closeMsg',
								config: {
									data: {}
								}
							})
						});
					}
				}
			})
		},
        /**
         * api get请求
         * @param context       store对象，内置属性和方法
         * @param config        请求配置项，请见 apiRequest 方法
         */
        apiGet(context, options) {
            options.config = { ...options.config, method: 'get'}
            context.dispatch('apiRequest', options)
        },
        /**
         * api post请求
         * @param context       store对象，内置属性和方法
         * @param config        请求配置项，请见 apiRequest 方法
         */
        apiPost(context, options) {
            if (options.file) {
                options.config = {
                    ...options.config,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/form-data;charset=UTF-8;'
                    }
                }
            } else {
                options.config.data = qs.stringify(options.config.data)
                options.config = {
                    ...options.config,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;'
                    }
                }
            }
            console.log(options.config.data)

            context.dispatch('apiRequest', options)
        },
        /**
         * api 接口请求
         * @param context       store对象，内置属性和方法
         * @param api           api接口名称（链接）
         * @param config        axios 配置参数，请见官方文档
         * @param success       成功回调
         * @param fail          失败回调
         */
        apiRequest(context, { api, config, success, fail }) {
            config.params = { ...config.params, auth_token: context.getters.authToken}
            axios.request({
                url: globalConfig.api_url + api,
                ...config
            }).then(response => {
                if (response.data.code == 200) {
                    if (typeof success == 'function') success(response.data)
                } else if (response.data.code == 401) {
                    globalConfig.jumpWechatAuth()
                } else if (response.data.code == 402 || response.data.code == 403 || response.data.code == 404 || response.data.code == 405) {
                    if (typeof fail == 'function') fail(response.data)
                    context.state.router.push({ name: response.data.code})
                } else {
                    if (typeof fail == 'function') fail(response.data)
                }
            })
            .catch( error => {
                console.log(error)

            })

        }
    }
}