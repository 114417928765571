<template>
  <div class="app-body">
    <div class="app-body-header" :style="routeMeta.header && routeMeta.header.style ? routeMeta.header.style : {}">
      <div class="go-back-icon" @click="goBack" v-if="routeMeta.header && routeMeta.header.goBack">
        <img src="image/back.png" alt="返回上一页">
      </div>
      {{ routeMeta.header && routeMeta.header.title ?  routeMeta.header.title : '订单管理系统' }}
    </div>
    <router-view :class="{'app-content-box-layout': true, 'has-bottom-menu-layout': hasBottomNav}" @checkReachBottom="checkReachBottom"></router-view>
    <BottomNav class="bottom-nav" v-if="hasBottomNav" :active="activeNav"/>
    <div class="preview-img" v-if="previewImg" @click="closePreviewImg">
      <img :src="previewImg" alt="">
    </div>
  </div>
</template>

<script>

import BottomNav from './components/BottomNav.vue'
import {mapMutations, mapGetters, mapActions} from 'vuex'
export default {
  name: 'App',
  data() {
    return {

    }
  },
  created() {
    // 帮助 store 获取路由对象，以便 store 使用路由
    this.setRouter(this.$router)
    this.setUIFrameWork({
      dialog: this.$dialog
    })
  },
  computed: {
    ...mapGetters(['previewImg']),
    // 当前活动导航 （高亮导航）
    activeNav() {
      return this.$route.meta.activeNav ?? 'index'
    },
    routeMeta() {
      return this.$route.meta
    },
    // 是否显示底部导航
    hasBottomNav() {
      return this.$route.meta.activeNav === undefined ? false : true
    }
  },
  methods: {
    ...mapMutations(['setRouter', 'setUIFrameWork']),
    ...mapActions(['closePreviewImg']),
    // 检测内容盒子是否触底 （用于，例：滚动加载）
    checkReachBottom({ event, onReachBottom}) {
      if (event.target.scrollHeight - event.target.scrollTop < event.target.offsetHeight + 50) {
        if (typeof onReachBottom == 'function') onReachBottom()
      }
    },
    goBack() {
      this.$router.back(-1)
    }
  },
  components: {
    BottomNav
  }
}
</script>

<style>
html, body ,#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  width: 100%;
}
.app-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-body-header {
  flex: 0 0 auto;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.13rem;
  background: #FFFFFF;
  color: #333333;
  box-shadow: 0 0 0.2rem 0 #E6E6E6;
  z-index: 100;
}
.app-content-box-layout {
  flex: 1 1 auto;
  overflow-y: scroll;
  padding: 1rem;
}
.bottom-nav {

}
.go-back-icon {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.go-back-icon img {
  width: 0.63rem;
  height: 1rem;
}
.list-bottom-tip {
  margin:1rem 0 0;
  font-size: 0.75rem;
  color: #999;
}
.preview-img {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0,0,0,0.7);
  vertical-align: middle;
}
.preview-img img {
  width: 96%;
}
/*.van-dialog__message {*/
/*  font-size: 2rem;*/
/*}*/
</style>
